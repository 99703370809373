<template>
  <!-- 代理列表组件 -->
  <div>
    <!-- 顶部 -->
    <div class="top">
      <div class="top_rigiht">
        <span
          >代理状态
          <a-select
            v-model="forbiddenStatus"
            style="width: 120px"
            :getPopupContainer="
              (triggerNode) => triggerNode.parentNode || document.body
            "
            @change="changeForbiddenStatus"
          >
            <a-select-option v-for="item in forbiddenStatusArr" :key="item.key">
              {{ item.value }}
            </a-select-option>
          </a-select>
        </span>
        <span
          >代理等级
          <a-select
            v-model="agentId"
            style="width: 120px"
            :getPopupContainer="
              (triggerNode) => triggerNode.parentNode || document.body
            "
            @change="changeAgentLevel"
          >
            <a-select-option v-for="item in agentLevelArr" :key="item.level">
              {{ item.text }}
            </a-select-option>
          </a-select>
        </span>
        <span>
          手机号
          <a-input
            v-model="searchVal"
            placeholder="请输入代理手机号"
            style="width: 200px"
            @pressEnter="searchClick"
          >
          </a-input>
        </span>
        <p class="s-padded-top">
          <span>
            代理姓名：
            <a-input
              v-model="searchVal"
              placeholder="请输入代理姓名"
              style="width: 200px"
              @pressEnter="searchClick"
            >
            </a-input>
          </span>
          <span>
            到期时间：
            <a-range-picker @change="onRangeDateChange"></a-range-picker>
          </span>
          <a-button
            icon="search"
            type="primary"
            @click="searchClick"
            :disabled="isDisableSearch"
            >搜索</a-button
          >
        </p>
      </div>
    </div>
    <div class="s-padded-top">
      <a-button
        icon="plus"
        type="primary"
        @click="addProxy"
        v-show="isShowAdd"
      >
        添加代理
      </a-button>
    </div>
    <!-- 表格 -->
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="false"
      :loading="tableLoading"
      :scroll="{x: 2100}"
    >
      <template slot="agentLevel" slot-scope="agentLevel, record">
        {{renderAgentLevel(agentLevel)}}
      </template>
      <template slot="parentAgentName" slot-scope="parentAgentName, record">
        {{parentAgentName || '无'}}
      </template>
      <!-- <template slot="agentRegion" slot-scope="agentRegion, record">
        {{agentRegion}}-{{record.city}}
      </template> -->
      <span slot="membershipGeneratedNumber" slot-scope="orderCount, record">
        <span class="mr10">{{ record.membershipGeneratedNumber }}</span>
        <a @click="membershipGeneratedNumberClick(record)"
          >详情</a
        >
      </span>
      <span slot="orderCount" slot-scope="orderCount, record">
        <span class="mr10">{{ record.orderCount }}</span>
        <a v-show="record.orderCount > 0" @click="orderCountClick(record)"
          >详情</a
        >
      </span>
      <span slot="commissionSum" slot-scope="commissionSum, record">
        <span class="mr10">{{ record.commissionSum }}</span>
        <a
          v-show="record.commissionSum > 0 && isShowAgent"
          @click="commissionClick(record)"
          >详情</a
        >
      </span>
      <span slot="forbiddenStatus" slot-scope="forbiddenStatus, record">
        <span v-show="record.forbiddenStatus == 1">正常</span>
        <span v-show="record.forbiddenStatus == 2">停用</span>
      </span>
      <span slot="operation" slot-scope="operation, record">
        <a
          class="margin_right20"
          v-show="record.forbiddenStatus == 1 && isShowDisable"
          @click="disableClick(record)"
        >
          停用
        </a>
        <a
          class="margin_right20"
          v-show="record.forbiddenStatus == 2 && isShowEnable"
          @click="enableClick(record)"
        >
          启用
        </a>
        <a
          v-show="isShowEdit"
          @click="editClick(record)"
          :disabled="isDisabledEdit"
          >编辑</a
        >
      </span>
    </a-table>
    <!-- 分页功能 -->
    <MyPagination
      :count="count"
      :pageNo="pageNo"
      @showSizeChangeFn="showSizeChangeFn"
      v-show="!tableLoading"
    />
  </div>
</template>

<script>
import MyPagination from "@/components/pagination/MyPagination";
import {
  GgentUsersApi,
  GgentSelectlabelApi,
  GgentDisableApi,
  GgentEnableApi,
  OrderCodesApi,
} from "@/request/api/marketingCenter";
import { Modal, message } from "ant-design-vue";
import { codeFn } from "@/utils/tools";
import {AgentLevel} from '@/utils/constants';

export default {
  components: { MyPagination },
  created() {
    this.GgentUsersFn();
    this.GgentSelectlabelFn();
    if (codeFn("/admin/agent/setting")) this.isShowAdd = true;
    if (codeFn("/admin/agent/enable/{agentUserId}")) this.isShowEnable = true;
    if (codeFn("/admin/agent/disable/{agentUserId}")) this.isShowDisable = true;
    if (codeFn("/admin/agent")) this.isShowAgent = true;
    if (codeFn("/admin/agent/update")) this.isShowEdit = true;
  },
  data() {
    return {
      isDisableSearch: false, // 搜索按钮是否禁用
      searchVal: "",
      tableLoading: true, // 表格loading图
      pageNo: 1,
      pageSize: 20,
      count: 0, // 列表总条数
      forbiddenStatus: "", //代理状态的默认值
      forbiddenStatusArr: [
        { key: "", value: "全部状态" },
        { key: "1", value: "正常" },
        { key: "2", value: "停用" },
      ], // 代理状态数组
      agentId: "", // 代理等级的默认值
      agentLevel: '',
      dateRange: [], // 过期时间段
      agentLevelArr: [{ level: "", text: "全部等级" }],
      columns: [
        {
          title: "账号",
          fixed: 'left',
          dataIndex: "userName",
          key: "userName",
          width: "100px",
          scopedSlots: { customRender: "userName" }
        },
        {
          title: "姓名",
          fixed: 'left',
          dataIndex: "realName",
          key: "realName",
          width: "100px",
          scopedSlots: { customRender: "realName" }
        },
        {
          title: "手机号码",
          dataIndex: "phone",
          key: "phone",
          width: "120px",
          scopedSlots: { customRender: "phone" }
        },
        {
          title: "代理等级",
          dataIndex: "agentLevel",
          key: "agentLevel",
          width: "100px",
          scopedSlots: { customRender: "agentLevel" }
        },
        {
          title: "上级代理",
          dataIndex: "parentAgentName",
          key: "parentAgentName",
          width: "100px",
          scopedSlots: { customRender: "parentAgentName" }
        },
        {
          title: "代理区域",
          dataIndex: "agentRegion",
          key: "agentRegion",
          width: "100px",
          scopedSlots: { customRender: "agentRegion" }
        },
        {
          title: "保证金",
          dataIndex: "securityDeposit",
          key: "securityDeposit",
          width: "100px",
          scopedSlots: { customRender: "securityDeposit" }
        },
        {
          title: "会员卡预购数",
          dataIndex: "membershipNumber",
          key: "membershipNumber",
          width: "100px",
          scopedSlots: { customRender: "membershipNumber" }
        },
        {
          title: "已生成会员卡",
          dataIndex: "membershipGeneratedNumber",
          key: "membershipGeneratedNumber",
          width: "100px",
          scopedSlots: { customRender: "membershipGeneratedNumber" }
        },
        {
          title: "会员卡订单数",
          dataIndex: "membershipOrderNumber",
          key: "membershipOrderNumber",
          width: "100px",
          scopedSlots: { customRender: "membershipOrderNumber" }
        },
        {
          title: "分成比例",
          dataIndex: "ratio",
          key: "ratio",
          width: "100px",
          scopedSlots: { customRender: "ratio" }
        },
        {
          title: "精品馆订单数量",
          dataIndex: "boutiqueOrderNumber",
          key: "boutiqueOrderNumber",
          width: "100px",
          scopedSlots: { customRender: "boutiqueOrderNumber" }
        },
        {
          title: "代理订单数量",
          dataIndex: "orderCount",
          key: "orderCount",
          width: "100px",
          scopedSlots: { customRender: "orderCount" }
        },
        {
          title: "累计收益",
          dataIndex: "commissionSum",
          key: "commissionSum",
          width: "100px",
          scopedSlots: { customRender: "commissionSum" }
        },
        {
          title: "可提现收益",
          dataIndex: "cashableIncome",
          key: "cashableIncome",
          width: "100px",
          scopedSlots: { customRender: "cashableIncome" }
        },
        {
          title: "代理状态",
          dataIndex: "forbiddenStatus",
          key: "forbiddenStatus",
          width: "100px",
          scopedSlots: { customRender: "forbiddenStatus" }
        },
        {
          title: "加入时间",
          dataIndex: "createTime",
          width: "12%",
          sorter: true,
          key: "createTime",
          sorter: (a, b) => {
            let aTime = new Date(a.createTime).getTime();
            let bTime = new Date(b.createTime).getTime();
            return aTime - bTime;
          },
        },
        {
          title: "开始时间",
          dataIndex: "validPeriodStart",
          width: "12%",
          sorter: true,
          key: "validPeriodStart",
          sorter: (a, b) => {
            let aTime = new Date(a.validPeriodStart).getTime();
            let bTime = new Date(b.validPeriodStart).getTime();
            return aTime - bTime;
          },
        },
        {
          title: "结束时间",
          dataIndex: "validPeriodEnd",
          width: "12%",
          sorter: true,
          key: "validPeriodEnd",
          sorter: (a, b) => {
            let aTime = new Date(a.validPeriodEnd).getTime();
            let bTime = new Date(b.validPeriodEnd).getTime();
            return aTime - bTime;
          },
        },
        {
          title: "操作",
          key: "operation",
          dataIndex: "operation",
          fixed: 'right',
          scopedSlots: { customRender: "operation" },
        },
      ],
      tableData: [],
      isDisabledEdit: false, // 编辑的按钮是否禁用
      isShowAdd: false, // 新增是否隐藏
      isShowEnable: false, // 启用是否隐藏
      isShowDisable: false, // 停用是否隐藏
      isShowAgent: false, // 累计佣金是否隐藏
      isShowEdit: false, // 编辑是否隐藏
    };
  },
  methods: {
    renderAgentLevel(agentLevel) {
      return AgentLevel[agentLevel];
    },
    // 添加代理按钮
    addProxy() {
      this.$router.push("/marketingCenter/proxyManage/addProxy/0");
    },
    // 代理状态改变的回调
    changeForbiddenStatus(value) {
      this.forbiddenStatus = value;
      this.GgentUsersFn();
    },
    // 代理等级改变的回调
    changeAgentLevel(value) {
      // this.agentId = value;
      this.agentLevel = value;
      this.GgentUsersFn();
    },
    // 到期时间
    onRangeDateChange(date, dateString) {
      this.dateRange = dateString;
      this.GgentUsersFn();
    },
    // 回车和搜索的按钮
    searchClick() {
      this.tableLoading = true;
      this.pageNo = 1;
      this.isDisableSearch = true;
      setTimeout(() => {
        this.isDisableSearch = false;
      }, 3000);
      this.GgentUsersFn();
    },
    // 已生成会员卡数量详情按钮
    membershipGeneratedNumberClick(record) {
      this.$router.push({
        path: '/marketingCenter/InviteMember',
        query: {
          agentUserId: record.agentUserId
        }
      });
    },
    //代理订单数量详情按钮
    orderCountClick(record) {
      OrderCodesApi({ agentUserId: record.agentUserId }).then(
        ({ code, data }) => {
          if (code == 200) {
            sessionStorage.setItem("orders", JSON.stringify(data));
            location.href = "/orderformManage/orderformList";
          }
        }
      );
    },
    // 佣金详情按钮
    commissionClick(record) {
      location.href = `/marketingCenter/proxyManage/proxycommission/${record.agentUserId}/${record.realName}/${record.agentRegion}/${record.agentLevel}/${record.createTime}/${record.commissionSum}`;
    },
    // 停用按钮
    disableClick(record) {
      Modal.confirm({
        title: "提示",
        content: "停用代理后, 相关权限会被取消,是否停用",
        okText: "确认",
        okType: "danger",
        cancelText: "取消",
        width: "500px",
        onOk() {
          GgentDisableApi({ agentUserId: record.key }).then(({ code }) => {
            if (code == 200) {
              message.success("停用成功", 1);
              record.forbiddenStatus = 2;
            }
          });
        },
      });
    },
    // 启用按钮
    enableClick(record) {
      GgentEnableApi({ agentUserId: record.key }).then(({ code }) => {
        if (code == 200) {
          message.success("启用成功", 1);
          record.forbiddenStatus = 1;
        }
      });
    },
    // 编辑的按钮
    editClick(record) {
      this.isDisabledEdit = true;
      setTimeout(() => {
        this.isDisabledEdit = false;
      }, 3000);
      location.href = `/marketingCenter/proxyManage/addProxy/${record.key}`;
    },
    // 分页功能切换的回调
    showSizeChangeFn(current, pageSize) {
      this.pageNo = current;
      this.pageSize = pageSize;
      this.GgentUsersFn();
    },
    // 获取代理列表函数
    GgentUsersFn() {
      GgentUsersApi({
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        forbiddenStatus: this.forbiddenStatus, //  代理状态 1:正常 2:停用
        // agentId: this.agentId, // 代理等级
        agentLevel: this.agentLevel, // 代理等级
        keyword: this.searchVal, // 搜索关键字-支持代理账号、姓名、手机号模糊
      }).then(({ code, data }) => {
        if (code == 200) {
          this.tableLoading = false;
          data.data.forEach((item) => {
            item.key = item.agentUserId;
          });
          this.tableData = data.data;
          this.count = data.count;
        }
      });
    },
    // 代理等级下拉栏函数
    GgentSelectlabelFn() {
      const levelArr = Object.keys(AgentLevel).map(level => {
        return {
          level: level,
          text: AgentLevel[level]
        }
      });
      this.agentLevelArr = this.agentLevelArr.concat(levelArr);
      // GgentSelectlabelApi().then(({ code, data }) => {
      //   if (code == 200) this.agentLevelArr.push(...data);
      // });
    },
  },
};
</script>
 
<style lang="less" scoped>
.s-padded-top {
  padding-top: 15px;
}
.top {
  display: flex;
  justify-content: space-between;
  .top_rigiht {
    span {
      margin-right: 30px;
      /deep/.ant-select {
        margin-left: 10px;
      }
    }
  }
}
/deep/ .ant-table-wrapper {
  margin-top: 20px;
}
/deep/.ant-table-thead > tr > th {
  font-weight: 600;
}
.mr10 {
  margin-right: 10px;
}
</style>