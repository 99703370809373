<template>
  <!-- 代理管理页面 -->
  <div class="padding24 color_white" id="proxy_manage">
    <div v-if="$route.meta.isShow">
      <a-tabs :activeKey="activeKey" @change="changeTabs">
        <a-tab-pane key="1" tab="代理列表">
          <ProxyList v-if="activeKey == 1" />
        </a-tab-pane>
        <a-tab-pane key="2" tab="代理配置">
          <ProxyConfig v-if="activeKey == 2" />
        </a-tab-pane>
      </a-tabs>
      <!-- 返回顶部 -->
      <a-back-top :target="targetFn" :visibilityHeight="100" />
    </div>
    <router-view />
  </div>
</template>
<script>
import ProxyList from "./proxy/ProxyList";
import ProxyConfig from "./proxy/ProxyConfig";
export default {
  created() {
    let proxyActiveKey = localStorage.getItem("proxyActiveKey");
    this.activeKey = proxyActiveKey ? proxyActiveKey : "1";
  },
  components: { ProxyList, ProxyConfig },
  data() {
    return {
      activeKey: "1",
    };
  },
  methods: {
    // 回到顶部的函数
    targetFn() {
      return document.querySelector("#proxy_manage");
    },
    // 切换tab栏
    changeTabs(activeKey) {
      this.activeKey = activeKey;
    },
  },
};
</script>
 
<style lang="less" scoped>
#proxy_manage {
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
}
</style>