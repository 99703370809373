<template>
  <!-- 代理配置组件 -->
  <div>
    <!-- 顶部 -->
    <div class="top">
      <div class="top_left">
        <!-- <a-button type="primary"> 添加代理 </a-button> -->
      </div>
      <div class="top_rigiht">
        <a-input
          v-model="searchVal"
          placeholder="请输入代理等级名称"
          style="width: 200px"
          @pressEnter="searchClick"
        >
          <a-icon slot="prefix" type="search" />
        </a-input>
        <a-button
          icon="search"
          type="primary"
          @click="searchClick"
          :disabled="isDisableSearch"
          >搜索</a-button
        >
      </div>
    </div>
    <!-- 表格 -->
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="false"
      :loading="tableLoading"
    >
      <span slot="remark" slot-scope="remark, record">
        <a-popover placement="topLeft">
          <template slot="content">
            <p :class="record.remark.length > 30 ? 'width400' : 'width_auto'">
              {{ record.remark }}
            </p>
          </template>
          <span>{{ record.remark }}</span>
        </a-popover>
      </span>
      <span slot="operation" slot-scope="operation, record">
        <a
          v-show="isShowEdit"
          @click="editClick(record)"
          :disabled="isDisabledEdit"
          >编辑</a
        >
      </span>
    </a-table>
    <!-- 分页功能 -->
    <MyPagination
      :count="count"
      :pageNo="pageNo"
      @showSizeChangeFn="showSizeChangeFn"
      v-show="!tableLoading"
    />
  </div>
</template>

<script>
import MyPagination from "@/components/pagination/MyPagination";
import { AgentConfigsApi } from "@/request/api/marketingCenter";
import { codeFn } from "@/utils/tools";
export default {
  components: { MyPagination },
  created() {
    this.AgentConfigsFn();
    if (codeFn("/admin/agent/config/update/{id}")) this.isShowEdit = true;
  },
  data() {
    return {
      isDisabledEdit: false, // 编辑的按钮是否禁用
      tableLoading: true, // 表格的loading图
      isDisableSearch: false, // 搜索的按钮是否禁用
      searchVal: "", // 输入框的值
      pageNo: 1,
      pageSize: 20,
      count: 0,
      columns: [
        {
          title: "代理等级",
          dataIndex: "agentName",
          key: "agentName",
        },
        {
          title: "返佣奖励",
          dataIndex: "commission",
          key: "commission",
        },
        {
          title: "说明",
          dataIndex: "remark",
          key: "remark",
          width: "50%",
          ellipsis: true,
          scopedSlots: { customRender: "remark" },
        },
        {
          title: "操作",
          key: "operation",
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
        },
      ],
      tableData: [],
      isShowEdit: false, // 编辑是否隐藏
    };
  },
  methods: {
    // 编辑的按钮
    editClick(record) {
      this.$router.push(
        `/marketingCenter/proxyManage/addProxyConfig/${record.key}`
      );
    },
    // 回车和搜索按钮
    searchClick() {
      this.tableLoading = true;
      this.pageNo = 1;
      this.isDisableSearch = true;
      setTimeout(() => {
        this.isDisableSearch = false;
      }, 3000);
      this.AgentConfigsFn();
    },
    // 分页功能切换的回调
    showSizeChangeFn(current, pageSize) {
      this.pageNo = current;
      this.pageSize = pageSize;
      this.AgentConfigsFn();
    },
    // 获取代理配置列表函数
    AgentConfigsFn() {
      AgentConfigsApi({
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        keyword: this.searchVal,
      }).then(({ code, data }) => {
        if (code == 200) {
          this.tableLoading = false;
          data.data.forEach((item) => {
            item.key = item.id;
            item.remark = item.remark == "" ? "——" : item.remark;
          });
          this.tableData = data.data;
          this.count = data.count;
        }
      });
    },
  },
};
</script>
 
<style lang="less" scoped>
.top {
  display: flex;
  justify-content: space-between;
  .top_rigiht {
    span {
      margin-right: 30px;
    }
  }
}
.width400 {
  width: 400px;
}
.width_auto {
  width: auto;
}
/deep/ .ant-table-wrapper {
  margin-top: 20px;
}
/deep/.ant-table-thead > tr > th {
  font-weight: 600;
}
</style>